import { SVG } from './types';

export function PlusThin(props: SVG) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 13L4 11H20V13H4Z" fill="currentColor" />
      <path d="M11 4L13 4L13 20L11 20L11 4Z" fill="currentColor" />
    </svg>
  );
}
