import { Instagram, LinkedIn, Medium, BlueSky } from '@/components/icons';
import { styled } from '@/stitches.config';

const icons = {
  bluesky: BlueSky,
  linkedin: LinkedIn,
  medium: Medium,
  instagram: Instagram,
} as const;

type SocialIconProps = {
  channel: keyof typeof icons;
  url: string;
};

export function SocialIcon({ channel, url }: SocialIconProps) {
  const Icon = icons[channel];

  return (
    <Anchor
      aria-label={`${channel} logo`}
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      <Icon />
    </Anchor>
  );
}

const Anchor = styled('a', {
  $$size: '2.35rem',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: '1px solid $background',
  borderRadius: '50%',
  display: 'flex',
  height: '$$size',
  justifyContent: 'center',
  width: '$$size',
  color: '$background',
  fill: 'none',
  stroke: 'none',

  '&:hover': {
    backgroundColor: '$background',
    color: '$foreground',
    border: '1px solid white',
    transform: 'translateZ(0)', // Makes 1px border more smooth
  },

  '@bp2': {
    $$size: '1.5rem',
  },

  '& svg': {
    width: 24,
    height: 24,
    '@bp2': {
      width: 16,
      height: 16,
    },
  },
});
