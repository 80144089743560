import { Text } from '@/components/text';
import { ThemeSwitch } from '@/components/theme-switch';
import { styled } from '@/stitches.config';
import { InquiryBlock } from './contact/inquiry-block';
import { EngagementBlock } from './contact/engagement-block';
import { Badge } from '@/lib/sanity/queries/get-badge';
import { Box } from './box';
import { GridContainer } from './grid-container';
import { ButtonText } from './button-text';
import { AnnouncementBadge } from './announcement-badge';

interface FooterProps {
  badgeData: Badge | null;
  contactPersonType: 'business' | 'careers' | 'esg';
}

export function Footer({ contactPersonType, badgeData }: FooterProps) {
  return (
    <StyledFooter>
      <GridContainer
        css={{
          width: '100%',
          gridTemplateRows: 'auto',
          gridRowGap: '$6',
          '@bp2': {
            gridRowGap: '$8',
            gridTemplateRows: 'auto auto auto 1fr',
          },
          '@bp5': {
            gridRowGap: '$8',
            gridTemplateRows: 'auto auto 1fr',
          },
        }}
        useLargeGridStylesAtBp2
      >
        {badgeData && badgeData.showInFooter && (
          <AnnouncementBadge
            css={{
              gridRow: 4,
              gridColumn: '2 / -2',
              flexShrink: 0,
              width: '9rem',
              height: '9rem',
              marginInlineStart: '$8',
              marginBlockEnd: '$8',
              marginBlockStart: '$8',
              '@bp2': {
                marginBlockStart: 0,
                gridColumn: '2 / 9',
                gridRow: '3',
              },
              '@bp5': {
                position: 'absolute',
                gridRow: '1 / 2',
              },
            }}
            invert
            badgeData={badgeData}
            animate
            showColors
          />
        )}
        <Text
          css={{
            gridRow: 5,
            gridColumn: '2 / -2',
            alignSelf: 'end',
            capSize: 6,
            '@bp1': {
              capSize: 7,
            },
            '@bp2': {
              gridColumn: '3 / 14',
              gridRow: 4,
              capSize: 8,
            },
            '@bp5': {
              gridColumn: '2 / 8',
              capSize: 7,
              gridRow: 3,
              pt: 'min(30vh, 18rem)',
            },
            '@bp6': {
              capSize: 8,
            },
            '@bp7': {
              gridColumn: '2 / 9',
              capSize: 9,
            },
          }}
        >
          Data driven
          <br />
          experiences
        </Text>
        <ButtonText
          href="https://adc-consulting.com/"
          label="Part of ADC consulting"
          css={{
            gridRow: 1,
            gridColumn: '2 / -2',
            marginBlockStart: '$2',
            opacity: 0.5,
            transition: 'opacity 0.2s',
            '&:hover': {
              opacity: 1,
            },
            '@bp2': {
              gridColumn: '3 / 8',
              marginBlockStart: '$4',
            },
            '@bp5': {
              gridColumn: '8 / 13',
            },
            '@bp7': {
              gridColumn: '10 / 14',
            },
            '@bp8': {
              gridColumn: '11 / 15',
            },
          }}
          textCss={{ fontSize: '$1' }}
        />
        <InquiryBlock
          contactPersonType={contactPersonType}
          css={{
            gridRow: 2,
            gridColumn: '2 / -2',
            marginBlockStart: '$10',
            '@bp2': {
              gridColumn: '3 / 9',
              marginBlockStart: 0,
            },
            '@bp5': {
              margin: 0,
              marginBlockEnd: '-0.3rem',
              gridRow: 3,
              gridColumn: '8 / 13',
              alignSelf: 'end',
            },
            '@bp7': {
              gridColumn: '10 / 14',
            },
            '@bp8': {
              gridColumn: '11 / 15',
            },
          }}
        />
        <Box
          css={{
            gridRow: 7,
            gridColumn: '2 / -2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'stretch',
            '@bp2': {
              gridColumn: '11 / 18',
              gridRow: 1,
            },
            '@bp3': {
              gridColumn: '10 / 18',
              gridRow: 1,
            },
            '@bp5': {
              gridColumn: '13 / 18',
            },
            '@bp7': {
              gridColumn: '14 / 18',
            },
            '@bp8': {
              gridColumn: '15 / 18',
            },
          }}
        >
          <Box css={{ display: 'flex', gap: '$3' }}>
            <Text css={{ fontSize: '$1', opacity: 0.5 }}>
              © {new Date().getFullYear()}
            </Text>
            <ButtonText
              href="/privacy-policy"
              label="Privacy policy"
              textCss={{
                fontSize: '$1',
                opacity: 0.5,
                transition: 'opacity 0.2s',
                '&:hover': {
                  opacity: 1,
                },
              }}
            />
          </Box>
        </Box>
        <Box
          css={{
            gridRow: 1,
            gridColumn: '2 / -2',
            display: 'flex',
            justifyContent: 'end',
            '@bp2': {
              gridColumn: '16 / 17',
            },
            '@bp5': {
              gridColumn: -3,
            },
          }}
        >
          <ThemeSwitch
            css={{
              opacity: 0.5,
              '&:hover': {
                opacity: 1,
              },
            }}
          />
        </Box>
        <EngagementBlock
          css={{
            gridRow: 3,
            gridColumn: '2 / -2',
            maxWidth: 'auto',
            marginBlockStart: '$10',
            '@bp1': {
              maxWidth: '20rem',
            },
            '@bp2': {
              maxWidth: 'none',
              gridColumn: '11 / 17',
              gridRow: 2,
              marginBlockStart: 0,
            },
            '@bp3': {
              gridColumn: '10 / 17',
            },
            '@bp5': {
              maxWidth: 'none',
              gridColumn: '13 / 18',
              gridRow: 3,
              alignSelf: 'end',
              mb: '-0.225rem',
            },
            '@bp7': {
              gridColumn: '14 / 18',
            },
            '@bp8': {
              gridColumn: '15 / 18',
            },
          }}
        />
      </GridContainer>
    </StyledFooter>
  );
}

const StyledFooter = styled('footer', {
  display: 'flex',
  alignItems: 'stretch',
  paddingBlockStart: '$7',
  paddingBlockEnd: '$10',
  minHeight: 'min(50vh, 30rem)',
  color: '$background',
});
