import { Box } from '@/components/box';
import { ArrowRight } from '@/components/icons/arrow-right';
import { Text } from '@/components/text';
import { CSS, styled } from '@/stitches.config';
import { keys } from '@/utils/keys';
import { SocialIcon } from './social-icon';
import { Description } from './styled-text';

const socialUrls = {
  instagram: 'https://www.instagram.com/cleverfranke',
  bluesky: 'https://bsky.app/profile/cleverfranke.com',
  linkedin: 'https://www.linkedin.com/company/clever-franke',
  medium: 'https://medium.com/clever-franke',
} as const;

export function EngagementBlock(props: { css?: CSS }) {
  return (
    <Wrapper css={props?.css}>
      <Text css={{ display: 'block', fontSize: '$3', marginBlockEnd: '$3' }}>
        Receive updates
      </Text>

      <Description>
        Subscribe to our newsletter to receive quarterly updates.
      </Description>

      <Form
        action="https://cleverfranke.createsend.com/t/d/s/njdydu/"
        method="post"
        conversion-on-submit="{ label: 'vW6mCNyo2moQw77m2AM' }"
      >
        <Box css={{ position: 'relative' }}>
          <FormInput
            id="cmEmailAddress"
            name="cm-njdydu-njdydu"
            placeholder="Enter email here"
            required
            type="email"
          />
          <FormSubmit type="submit" aria-label="Sign up">
            <ArrowRight />
          </FormSubmit>
        </Box>
      </Form>

      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'start',
          columnGap: '$5',
          rowGap: '$4',
          '@bp2': {
            flexDirection: 'row',
            alignItems: 'center',
            rowGap: '$2',
          },
        }}
      >
        <Text
          css={{
            fontSize: '$2',
            '@bp2': {
              fontSize: '$3',
            },
          }}
        >
          Or follow us on social
        </Text>

        <Box
          as="ul"
          css={{ display: 'flex', gap: '$4', '@bp2': { gap: '$2' } }}
        >
          {keys(socialUrls).map((channel) => (
            <SocialLink key={`${channel}`}>
              <SocialIcon channel={channel} url={socialUrls[channel]} />
            </SocialLink>
          ))}
        </Box>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  position: 'relative',
});

const FormInput = styled('input', {
  width: '100%',
  backgroundColor: 'transparent',
  border: '1px solid transparent',
  borderBottom: '1px solid #525252',
  color: '$background',
  fontSize: '$3',
  padding: '$1 $8 $1 0',
  fontFamily: '$neutral',

  '&::placeholder': {
    color: '$gray6',
  },

  '&:focus-visible': {
    outline: 'none',
    border: '1px solid $background',
    backgroundColor: 'transparent',
  },
});

const FormSubmit = styled('button', {
  position: 'absolute',
  top: 0,
  right: '$3',
  bottom: '$2',
  width: '$6',
  backgroundColor: 'transparent',
  height: '100%',

  svg: {
    color: '$background',

    '@motion': {
      transition: 'transform 0.2s',
    },
  },
});

const Form = styled('form', {
  position: 'relative',
  margin: '0 0 $7',
  paddingBottom: '$6',

  '@bp3': {
    paddingBottom: 0,
  },
  '&:hover': {
    [`& ${FormSubmit}`]: {
      svg: {
        transform: 'translateX($space$1)',
      },
    },
  },
});

const SocialLink = styled('li', {});
