import { Box } from '@/components/box';
import { Text } from '@/components/text';
import {
  focusStateStyles,
  Underline,
  thinnerUnderlineHoverStyles,
} from '@/components/underline';
import { CSS, styled } from '@/stitches.config';
import Image, { StaticImageData } from 'next/image';
import { Description } from './styled-text';
import photoOfThomas from './thomas-clever.jpg';
import photoOfJulia from './julia-hendriks.jpg';
import photoOfNicolas from './nicolas-zaragoza.jpg';
import { ButtonText } from '@/components/button-text';

const CONTACT_DETAILS: Record<
  'business' | 'careers' | 'esg',
  {
    name: string;
    description: string;
    phoneNumber: string;
    photo: StaticImageData;
    email: string;
  }
> = {
  business: {
    name: 'Thomas Clever',
    description:
      'If you’d like to learn more about how we can add value to your business, contact Thomas Clever.',
    phoneNumber: '+31 6 19 55 29 81',
    photo: photoOfThomas,
    email: 'thomas@cleverfranke.com',
  },
  careers: {
    name: 'Julia Hendriks',
    description:
      'If you’d like to learn more about a job opening or internship, contact Julia Hendriks.',
    phoneNumber: '+31 30 785 19 26',
    photo: photoOfJulia,
    email: 'julia@cleverfranke.com',
  },
  esg: {
    name: 'Nicolás Zaragoza',
    description:
      'To discuss how we can support your ESG initiatives, contact Nicolás Zaragoza.',
    phoneNumber: '+31 6 13 27 04 45',
    photo: photoOfNicolas,
    email: 'nicolaszaragoza@cleverfranke.com',
  },
};

export function InquiryBlock(props: {
  css?: CSS;
  contactPersonType: 'business' | 'careers' | 'esg';
}) {
  return (
    <Wrapper css={props?.css}>
      <Text css={{ display: 'block', fontSize: '$3', marginBlockEnd: '$3' }}>
        Get in touch
      </Text>

      <Description css={{ marginBlockEnd: '1.74rem' }}>
        {CONTACT_DETAILS[props.contactPersonType].description}
      </Description>

      <Box css={{ display: 'flex', gap: '$4', alignItems: 'center' }}>
        <Box
          css={{
            gridArea: 'image',
            width: 50,
            height: 50,
            flexShrink: 0,
            overflow: 'hidden',
          }}
        >
          <Image
            src={CONTACT_DETAILS[props.contactPersonType].photo}
            alt={`Photo of ${CONTACT_DETAILS[props.contactPersonType].name}`}
            layout="responsive"
            width={480}
            height={480}
            placeholder="blur"
          />
        </Box>
        <Text
          as="address"
          size={2}
          css={{
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.35rem',
            paddingTop: '$1',
            margin: 0,
          }}
        >
          <ButtonText
            href={`mailto:${CONTACT_DETAILS[props.contactPersonType].email}`}
            label={CONTACT_DETAILS[props.contactPersonType].email}
          />
          <Anchor
            href={`tel:${CONTACT_DETAILS[
              props.contactPersonType
            ].phoneNumber.replace(/\s+/g, '')}`}
          >
            {CONTACT_DETAILS[props.contactPersonType].phoneNumber}
            <Underline />
          </Anchor>
        </Text>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled('div', {});

const Anchor = styled('a', {
  position: 'relative',
  display: 'inline-flex',
  fontStyle: 'normal',
  color: '$background',
  textDecoration: 'none',
  overflow: 'hidden',

  '&:hover, &:active': {
    [`${Underline} > div `]: thinnerUnderlineHoverStyles,
  },

  '&:focus-visible': {
    ...focusStateStyles,
    outlineOffset: '2px',
    [`${Underline} > div `]: thinnerUnderlineHoverStyles,
  },
});
