import { SVG } from './types';

export function Minus(props: SVG) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 13L4 11H20V13H4Z" fill="currentColor" />
      <path d="M20 11L20 13L4 13L4 11L20 11Z" fill="currentColor" />
    </svg>
  );
}
